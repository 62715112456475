import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import LinearGradientOne from "../components/layout/backgrounds/LinearGradientOne";
import useStore from "../store";
import useSignIn from "react-auth-kit/hooks/useSignIn";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

// const defaultTheme = createTheme();

export default function SignIn() {
  const userData = useStore((state) => state.userData);
  const setUserData = useStore((state) => state.setUserData);
  const logout = useStore((state) => state.logout);
  const theme = useTheme();
  const signIn = useSignIn();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/features/account/sign_in`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.get("email"),
        password: data.get("password"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) {
          alert(data.error);
          return;
        }
        signIn({
          auth: {
            token: data.token,
            type: "Bearer",
          },
          expiresIn: 86400,
          tokenType: "Bearer",
          authState: { email: data.user.email },
        });
        setUserData(data.user);
      });
  };

  return userData !== null && userData !== undefined ? (
    <Grid
      container
      component="main"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 1,
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        item
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          maxWidth: "80vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            You are signed in as {userData.name}.
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              flexGrow: 1,
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/"
              sx={{
                width: "100%",
              }}
            >
              Go to Home
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/profile"
              sx={{
                width: "100%",
              }}
            >
              Go to Profile
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              onClick={logout}
              to="/"
              sx={{
                width: "100%",
              }}
            >
              Logout
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <div>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CssBaseline />
        <Grid
          item
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100vw",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <LinearGradientOne />
        </Grid>
        <Grid
          item
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "2.5rem",
            backgroundColor: `${theme.palette.background.default}D9 !important`,
            maxWidth: "50rem",
            margin: "0 2.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
