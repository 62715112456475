import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AppAppBar from "./components/AppAppBar";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import colorScheme from "./theme/colorScheme";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

import useStore from "./store";
import Profile from "./pages/Profile";
import Leaderboard from "./pages/Leaderboard";
import User from "./pages/User";

import AuthProvider from "react-auth-kit";
import authStore from "./authStore";

function App() {
  const isDarkMode = useStore((state) => state.isDarkMode);
  const toggleColorMode = useStore((state) => state.toggleColorMode);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode
          ? colorScheme.dark.background
          : colorScheme.light.background,
      },
      primary: {
        main: isDarkMode ? colorScheme.dark.primary : colorScheme.light.primary,
      },
      secondary: {
        main: isDarkMode
          ? colorScheme.dark.secondary
          : colorScheme.light.secondary,
      },
      text: {
        primary: isDarkMode
          ? colorScheme.dark.onBackground
          : colorScheme.light.onBackground,
        secondary: isDarkMode
          ? colorScheme.dark.onSurface
          : colorScheme.light.onSurface,
      },
    },
  });

  React.useEffect(() => {
    console.log(authStore.initialCookieToken);
  });

  console.log(authStore);

  return (
    <AuthProvider store={authStore}>
      <ThemeProvider theme={theme}>
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Router>
            <AppAppBar mode={isDarkMode} toggleColorMode={toggleColorMode} />

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/user/:userId" element={<User />} />
            </Routes>
          </Router>
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
