import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set, get) => ({
      isDarkMode: true,
      toggleColorMode: () =>
        set((state) => ({ isDarkMode: !state.isDarkMode })),
      userData: null,
      setUserData: (newUserData) => set({ userData: newUserData }),
      logout: () => {
        set({ userData: null });
      },
      // removeAllBears: () => set({ bears: 0 }),
      // updateBears: (newBears) => set({ bears: newBears }),
    }),
    {
      name: "kyushu-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useStore;
