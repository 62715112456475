import React from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

function ScrollDownArrow({ scrollToSelector }) {
  return (
    <div>
      <IconButton
        children={<KeyboardArrowDownIcon fontSize="large" />}
        sx={{
          margin: "16px auto",
        }}
        size="large"
        onClick={() => {
          const element = document.querySelector(scrollToSelector);
          element.scrollIntoView({ behavior: "smooth" });
        }}
      />
    </div>
  );
}

ScrollDownArrow.propTypes = {
  scrollToSelector: PropTypes.string.isRequired,
};

export default ScrollDownArrow;
