import React from "react";
import useStore from "../store";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Grid,
  useTheme,
  CssBaseline,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LinearGradientOne from "../components/layout/backgrounds/LinearGradientOne";
import { Link } from "react-router-dom";
import ProfileImage from "../components/layout/partials/ProfileImage";
import Dropzone from "react-dropzone";

function Profile() {
  const userData = useStore((state) => state.userData);
  const setUserData = useStore((state) => state.setUserData);
  const logout = useStore((state) => state.logout);
  const [editingProfile, setEditingProfile] = React.useState(false);
  const [newProfileImageUrl, setNewProfileImageUrl] = React.useState("");
  const theme = useTheme();
  const navigate = useNavigate();

  async function changeUserProfileImageWithUrl(userId, profileImageUrl) {
    var imageBytesBase64 = "";
    if (profileImageUrl) {
      var data = await fetch(profileImageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((resolve) => {
            reader.onloadend = () => {
              resolve(reader.result);
            };
          });
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return "https://api.dicebear.com/7.x/pixel-art/png?seed=default&background=%23000000";
        });
      imageBytesBase64 = data;
    }

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/features/account/update_pfp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          profileImage: imageBytesBase64,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
      });
  }

  const [acceptedFile, setAcceptedFile] = React.useState(null);

  async function changeUserProfileImageWithFile(userId, acceptedFile) {
    var imageBytesBase64 = "";
    var file = acceptedFile;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    imageBytesBase64 = await new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/features/account/update_pfp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          profileImage: imageBytesBase64,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
      });
  }

  return userData === null || userData === undefined ? (
    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        item
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          maxWidth: "50rem",
          minWidth: "20rem",
          margin: "0 2.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            marginBottom: "1rem",
          }}
        >
          You are not signed in.
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            sx={{
              width: "100%",
            }}
          >
            Go to home
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
            sx={{
              width: "100%",
            }}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/signup"
            sx={{
              width: "100%",
            }}
          >
            Sign up
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : editingProfile ? (
    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        item
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          maxWidth: "50rem",
          minWidth: "25rem",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Profile Image URL"
            variant="outlined"
            value={newProfileImageUrl}
            onChange={(e) => setNewProfileImageUrl(e.target.value)}
            sx={{
              width: "100%",
              marginBottom: "1rem",
            }}
          />
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              gap: "1rem",
            }}
          >
            <Dropzone onDrop={(acceptedFile) => setAcceptedFile(acceptedFile)}>
              {({ getRootProps, getInputProps }) => (
                <section
                  style={{
                    width: "100%",
                    height: "112px",
                    border: "2.5px dashed gray",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    {...getRootProps()}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop image here,
                      <br />
                      or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            {acceptedFile && acceptedFile.length > 0 && (
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  marginBottom: "1rem",
                  color: "text.primary",
                  textAlign: "center",
                }}
              >
                Uploaded:
                <br />
                {acceptedFile[0].name}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              onClick={() => {
                setEditingProfile(false);
                setNewProfileImageUrl("");
              }}
              to="/profile"
              sx={{
                width: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              onClick={() => {
                setEditingProfile(false);
                if (newProfileImageUrl) {
                  changeUserProfileImageWithUrl(
                    userData._id,
                    newProfileImageUrl
                  );
                  setNewProfileImageUrl("");
                } else if (acceptedFile && acceptedFile.length > 0) {
                  changeUserProfileImageWithFile(userData._id, acceptedFile[0]);
                  setAcceptedFile(null);
                }
              }}
              to="/profile"
              sx={{
                width: "100%",
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <ProfileWrapper>
      <Grid
        item
        sx={{
          position: "fixed",
          zIndex: 1,
          width: "100vw",
          height: "100%",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        container
        sx={{
          padding: "2.5rem",
          margin: "0 2.5rem",
          maxWidth: "70rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          "@media (max-width: 768px)": {
            maxHeight: "95%",
            scale: "0.8",
          },
          overflowY: "auto",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",

            "@media (max-width: 768px)": {
              flexDirection: "column",

              "& > *": {
                marginBottom: "1rem",
              },
            },
          }}
        >
          <Grid
            item
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              "@media (max-width: 768px)": {
                textAlign: "center",
                flexDirection: "column",
                marginBottom: "1rem",
              },
            }}
          >
            <Typography variant="h4" color="text.primary">
              My Profile
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              textAlign: "end",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button
              onClick={() => {
                setEditingProfile(!editingProfile);
              }}
              variant="contained"
            >
              Edit Profile
            </Button>
            <Button
              onClick={() => {
                logout();
                navigate("/");
              }}
              variant="contained"
            >
              Logout
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            flexWrap: "nowrap",
            padding: "1rem 0",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              alignItems: "center",

              "& > div": {
                marginBottom: "1rem",
              },
            },
          }}
        >
          <Grid
            item
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "2.5rem",
            }}
          >
            <ProfileImage width={125} height={125} />
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-evenly",
              "@media (max-width: 768px)": {
                alignItems: "center",
                width: "100%",
              },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "1rem",
                "@media (max-width: 768px)": {
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                },
              }}
            >
              <Typography variant="h4" color="text.primary">
                {userData.username} ({userData.status})
              </Typography>
              <Typography variant="h6" color="text.primary">
                Rank: {userData.rank}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                "@media (max-width: 768px)": {
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                },
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "@media (max-width: 768px)": {
                    alignItems: "center",
                    marginBottom: "1rem",
                  },
                }}
              >
                <Typography variant="h6" color="text.primary">
                  Email:
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {userData.email}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-evenly",
                  "@media (max-width: 768px)": {
                    alignItems: "center",
                    marginBottom: "1rem",
                  },
                }}
              >
                <Typography variant="h6" color="text.primary">
                  Account Type:
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {userData.role}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-evenly",
                  "@media (max-width: 768px)": {
                    alignItems: "center",
                  },
                }}
              >
                <Typography variant="h6" color="text.primary">
                  Created At:
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {new Date(userData.created_at).toLocaleDateString("en-US") +
                    " " +
                    new Date(userData.created_at).toLocaleTimeString("en-US")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-evenly",
              "@media (max-width: 768px)": {
                alignItems: "center",
              },
            }}
          >
            <Typography variant="h6" color="text.primary">
              Event History
            </Typography>
            <Typography variant="body1" color="text.primary">
              {userData.eventHistory !== null
                ? "Coming Soon"
                : "No Event History"}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-evenly",
            }}
          >
            <Typography variant="h6" color="text.primary">
              Match History
            </Typography>
            {userData.matchHistory !== null ? (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  backgroundColor: theme.palette.action.hover,
                  position: "relative",
                  overflow: "auto",
                  maxHeight: 300,
                  "& ul": {
                    padding: 0,
                  },
                }}
                subheader={<li />}
              >
                {userData.matchHistory.map((match) => (
                  <li key={match.id}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <>
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" color="text.primary">
                                Challenger:
                              </Typography>
                              <ProfileImage
                                width={40}
                                height={40}
                                src={match.challenger.profileImage}
                              />
                              <Typography variant="body1" color="text.primary">
                                {match.challenger.name}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" color="text.primary">
                                Challenged:
                              </Typography>
                              <ProfileImage
                                width={40}
                                height={40}
                                src={match.challenged.profileImage}
                              />
                              <Typography variant="body1" color="text.primary">
                                {match.challenged.name}
                              </Typography>
                            </Grid>
                          </>
                        }
                      />
                    </ListItem>
                  </li>
                ))}
              </List>
            ) : (
              <Typography variant="body1" color="text.primary">
                No Match History
              </Typography>
            )}
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-evenly",
            }}
          >
            <Typography variant="h6" color="text.primary">
              VIP History
            </Typography>
            <Typography variant="body1" color="text.primary">
              {userData.vipHistory !== null ? "Coming Soon" : "No VIP History"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ProfileWrapper>
  );
}

export default Profile;

const ProfileWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
}));
