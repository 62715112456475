import React from "react";
import { styled } from "@mui/material/styles";

function LinearGradientOne() {
  return <LinearGradientOneWrapper></LinearGradientOneWrapper>;
}

export default LinearGradientOne;

const LinearGradientOneWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  background: `linear-gradient(320deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  backgroundSize: "400% 400%",
  WebkitAnimation: "AnimationName 13s ease infinite",
  MozAnimation: "AnimationName 13s ease infinite",
  OAnimation: "AnimationName 13s ease infinite",
  animation: "AnimationName 13s ease infinite",

  "@-webkit-keyframes AnimationName": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
  "@-moz-keyframes AnimationName": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
  "@-o-keyframes AnimationName": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
  "@keyframes AnimationName": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
}));
