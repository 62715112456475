import React from "react";
import BgVideoShuffle from "../components/BgVideoShuffle";
import HomeFeature from "../components/HomeFeature";

function Home() {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <BgVideoShuffle />
      <HomeFeature />
    </div>
  );
}

export default Home;
