import React, { useEffect } from "react";
import useStore from "../store";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Grid,
  useTheme,
  CssBaseline,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import LinearGradientOne from "../components/layout/backgrounds/LinearGradientOne";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../components/layout/partials/ProfileImage";

function User() {
  const userData = useStore((state) => state.userData);
  const [requestedUser, setRequestedUser] = React.useState({});
  const theme = useTheme();
  const navigate = useNavigate();
  let { userId } = useParams();

  async function challengeUser(userId) {
    if (!userData) {
      alert("Please sign in first");
      return;
    }
    if (userData.id === userId) {
      alert("You can't challenge yourself");
      return;
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/features/lb/challenge_player`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          challengerId: userData.id,
          challengedId: userId,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error !== undefined) {
          alert(responseJson.error.details);
        } else {
          navigate("/user/" + userId);
          alert(responseJson.message);
        }
      });
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/features/account/get_user_info/${userId}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error !== undefined) {
          setRequestedUser({});
        } else {
          setRequestedUser(responseJson);
        }
      });
  }, [userId]);

  return requestedUser?.id === null || requestedUser?.id === undefined ? (
    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        item
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          maxWidth: "50rem",
          minWidth: "20rem",
          margin: "0 2.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          User not found
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            sx={{
              width: "100%",
            }}
            onClick={() => navigate.goBack()}
          >
            Go back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <UserWrapper>
      <Grid
        item
        sx={{
          position: "fixed",
          zIndex: 1,
          width: "100vw",
          height: "100%",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        container
        sx={{
          padding: "2.5rem",
          margin: "0 2.5rem",
          maxWidth: "70rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          "@media (max-width: 768px)": {
            scale: "0.8",
          },
          overflowY: "auto",
        }}
      >
        <Grid
          item
          sx={{
            flexWrap: "nowrap",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",

            "@media (max-width: 768px)": {
              flexDirection: "column",

              "& > *": {
                marginBottom: "1rem",
              },
            },
          }}
        >
          <Grid item sx={{ textAlign: "start" }}>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                "@media (max-width: 768px)": {
                  textAlign: "center",
                  flexDirection: "column",
                  marginBottom: "1rem",
                },
              }}
            >
              <Typography
                variant="h4"
                color="text.primary"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {requestedUser.username}'s Profile
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              textAlign: "end",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Button
              onClick={() => challengeUser(requestedUser.id)}
              variant="contained"
            >
              Challenge User
            </Button>
            <Button onClick={() => {}} variant="contained">
              Add User
            </Button>
            <Button onClick={() => {}} variant="contained">
              Report User
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              flexWrap: "nowrap",
              padding: "1rem 0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                alignItems: "center",

                "& > div": {
                  marginBottom: "1rem",
                },
              },
            }}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "2.5rem",
              }}
            >
              <ProfileImage
                width={125}
                height={125}
                src={requestedUser.profileImage}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-between",
                width: "100%",

                "@media (max-width: 768px)": {
                  alignItems: "center",
                  width: "100%",
                },
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",

                  "@media (max-width: 768px)": {
                    flexDirection: "column",
                    alignItems: "center",
                    "& > *": {
                      marginBottom: "1rem",
                    },
                  },
                }}
              >
                <Typography variant="h4" color="text.primary">
                  {requestedUser.username} ({requestedUser.status})
                </Typography>
                <Typography variant="h6" color="text.primary">
                  Rank: {requestedUser.rank}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",

                  "@media (max-width: 768px)": {
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  },
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "space-evenly",
                    "@media (max-width: 768px)": {
                      alignItems: "center",
                      "& > *": {
                        marginBottom: "1rem",
                      },
                    },
                  }}
                >
                  <Typography variant="h6" color="text.primary">
                    Last Active:
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {requestedUser.lastSeen}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "space-evenly",
                    "@media (max-width: 768px)": {
                      alignItems: "center",
                      marginBottom: "1rem",
                    },
                  }}
                >
                  <Typography variant="h6" color="text.primary">
                    Account Type:
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {requestedUser.role}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "space-evenly",
                    "@media (max-width: 768px)": {
                      alignItems: "center",
                    },
                  }}
                >
                  <Typography variant="h6" color="text.primary">
                    Created At:
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {new Date(requestedUser.created_at).toLocaleDateString(
                      "en-US"
                    ) +
                      " " +
                      new Date(requestedUser.created_at).toLocaleTimeString(
                        "en-US"
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                "& > *": {
                  marginBottom: "1rem",
                },
              },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-evenly",
                "@media (max-width: 768px)": {
                  alignItems: "center",
                },
              }}
            >
              <Typography variant="h6" color="text.primary">
                Event History
              </Typography>
              <Typography variant="body1" color="text.primary">
                {requestedUser.eventHistory !== null
                  ? "Coming Soon"
                  : "No Event History"}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="h6" color="text.primary">
                Match History
              </Typography>
              {requestedUser.matchHistory !== null ? (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    backgroundColor: theme.palette.action.hover,
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 300,
                    "& ul": {
                      padding: 0,
                    },
                  }}
                  subheader={<li />}
                >
                  {requestedUser.matchHistory.map((match) => (
                    <li key={match.id}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  Challenger:
                                </Typography>
                                <ProfileImage
                                  width={40}
                                  height={40}
                                  src={match.challenger.profileImage}
                                />
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {match.challenger.username}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  Challenged:
                                </Typography>
                                <ProfileImage
                                  width={40}
                                  height={40}
                                  src={match.challenged.profileImage}
                                />
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {match.challenged.username}
                                </Typography>
                              </Grid>
                            </>
                          }
                        />
                      </ListItem>
                    </li>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" color="text.primary">
                  No Match History
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="h6" color="text.primary">
                VIP History
              </Typography>
              <Typography variant="body1" color="text.primary">
                {requestedUser.vipHistory !== null
                  ? "Coming Soon"
                  : "No VIP History"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UserWrapper>
  );
}

export default User;

const UserWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
}));
