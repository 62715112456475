import React from "react";
import styled from "styled-components";

function BgVideoShuffle() {
  return (
    <BgVideoShuffleWrapper>
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          width: "100%",
          height: "100vh",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          filter: "blur(15px) brightness(0.5)",
        }}
      >
        <source src="/assets/videos/bg.mp4" type="video/mp4" />
      </video>
      {/* <div className="overlay">
        <p>Overlay</p>
      </div> */}
    </BgVideoShuffleWrapper>
  );
}

export default BgVideoShuffle;

const BgVideoShuffleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
