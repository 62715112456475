import React from "react";
import styled from "@mui/material/styles/styled";
import ScrollDownArrow from "./layout/partials/ScrollDownArrow";
import ScrollUpArrow from "./layout/partials/ScrollUpArrow";
import { useTheme } from "@mui/material";

function HomeFeature() {
  const theme = useTheme();
  return (
    <HomeFeatureWrapper
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <HomeFeatureItemWrapper id="leaderboard-intro">
        <div></div>
        <div>
          <h2>Kyushu Leaderboard</h2>
          <p>
            Kyushu Leaderboard is a Call Of Duty: Black Ops 2 1v1 Leaderboard
            website that allows you to view and compare your stats against
            others on the leaderboard.
          </p>
        </div>
        <ScrollArrowWrapper>
          <ScrollDownArrow scrollToSelector="#leaderboard-mission" />
        </ScrollArrowWrapper>
      </HomeFeatureItemWrapper>
      <HomeFeatureItemWrapper>
        <div></div>
        <div id="leaderboard-mission">
          <h2>Why make a leaderboard in 2024?</h2>
          <p>Because I want to make a leaderboard. Get off my dick.</p>
        </div>
        <ScrollArrowWrapper>
          <ScrollUpArrow scrollToSelector="body" />
          <ScrollDownArrow scrollToSelector="#leaderboard-mission" />
        </ScrollArrowWrapper>
      </HomeFeatureItemWrapper>
    </HomeFeatureWrapper>
  );
}

export default HomeFeature;

const HomeFeatureWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "#leaderboard-intro *": {
    color: "white",
  },
}));

const HomeFeatureItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "transparent",
  color: theme.palette.primary.contrastText,
  div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  h2: {
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "2px 2px 4px #000000",
    marginBottom: "1rem",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      lineHeight: "1.5",
      padding: "0 1rem",
    },
  },
  p: {
    maxWidth: "50rem",
    margin: "0 2.5rem",
    fontSize: "1.5rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      lineHeight: "1.5",
      padding: "0 1rem",
    },
  },
}));

const ScrollArrowWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "Relative",
  bottom: 0,
  [theme.breakpoints.down("md")]: {
    position: "relative",
    bottom: "unset",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
}));
