const colorScheme = {
  light: {
    primary: "#faaacc",
    secondary: "#45abe6",
    background: "#d8d8d8",
    surface: "#f0f0f0",
    onBackground: "#000000",
    onSurface: "#000000",
    onPrimary: "#ffffff",
    onSecondary: "#ffffff",
  },
  dark: {
    primary: "#faaacc",
    secondary: "#45abe6",
    background: "#111111",
    surface: "#272727",
    onBackground: "#ffffff",
    onSurface: "#ffffff",
    onPrimary: "#ffffff",
    onSecondary: "#ffffff",
  },
};

export default colorScheme;
