import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { IconButton, Popover } from "@mui/material";
import ProfileImage from "./layout/partials/ProfileImage";

function AppAppBar({ mode, toggleColorMode }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const userData = useStore((state) => state.userData);
  const logout = useStore((state) => state.logout);
  const [hoveringOverAvatar, setHoveringOverAvatar] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <AppBarWrapper>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <h1>KLB</h1>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => navigate("/")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/leaderboard")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/leaderboard"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    Leaderboard
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/hof"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    HOF
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("pricing")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/team"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    Team
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("faq")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/faq"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    FAQ
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("contact")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography
                    variant="body2"
                    color={
                      window.location.pathname === "/contact"
                        ? "primary"
                        : "text.primary"
                    }
                  >
                    Contact
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              {userData !== null && userData !== undefined ? (
                <>
                  <IconButton
                    color="primary"
                    component="a"
                    onClick={() => {}}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 0.5,
                      padding: 0.75,
                      paddingX: 1.5,
                      borderRadius: 30,
                      backgroundColor: hoveringOverAvatar
                        ? "primary.100"
                        : "text.primary",
                    }}
                    onMouseEnter={() => setHoveringOverAvatar(true)}
                    onMouseLeave={() => setHoveringOverAvatar(false)}
                  >
                    <ProfileImage width={24} height={24} />
                    <Typography variant="body2">{userData.username}</Typography>
                    <Popover
                      open={hoveringOverAvatar}
                      anchorEl={document.getElementById("avatar-menu")}
                      onClose={() => setHoveringOverAvatar(false)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        View profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          logout();
                        }}
                      >
                        Log out
                      </MenuItem>
                    </Popover>
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() => navigate("/login")}
                  >
                    Sign in
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    component="a"
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode
                      mode={mode}
                      toggleColorMode={toggleColorMode}
                    />
                  </Box>
                  <MenuItem onClick={() => navigate("/")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      Home
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/leaderboard")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/leaderboard"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      Leaderboard
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("highlights")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/hof"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      HOF
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("pricing")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/team"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      Team
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("faq")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/faq"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      FAQ
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("contact")}>
                    <Typography
                      variant="body3"
                      color={
                        window.location.pathname === "/contact"
                          ? "primary"
                          : "text.primary"
                      }
                    >
                      Contact
                    </Typography>
                  </MenuItem>
                  <Divider />
                  {userData ? (
                    <>
                      <MenuItem
                        onClick={() => navigate("/profile")}
                        sx={{ py: "6px", px: "12px" }}
                      >
                        <Typography
                          variant="body3"
                          color={
                            window.location.pathname === "/profile"
                              ? "primary"
                              : "text.primary"
                          }
                        >
                          Profile
                        </Typography>
                      </MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        onClick={() => {
                          logout();
                          navigate("/");
                        }}
                        sx={{ width: "100%", mt: "12px" }}
                      >
                        <Typography
                          variant="body3"
                          color={
                            window.location.pathname === "/profile"
                              ? "primary"
                              : "text.primary"
                          }
                        >
                          Log out
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          sx={{ width: "100%" }}
                          onClick={() => navigate("/signup")}
                        >
                          Sign up
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component="a"
                          sx={{ width: "100%" }}
                          onClick={() => navigate("/login")}
                        >
                          Sign in
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </AppBarWrapper>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.bool.isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;

const AppBarWrapper = styled("nav")(
  ({ theme }) => `
  h1 {
    margin: 0;
    font-size: 1.25rem;
    padding: 0 16px;
    color: ${theme.palette.text.primary};
  }
`
);
