import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import useStore from "../../../store";

function ProfileImage({ width, height, src }) {
  const userData = useStore((state) => state.userData);

  return (
    <Avatar
      alt={userData?.username}
      src={src || userData?.profileImage}
      sx={{ width: width, height: height }}
      id="avatar-menu"
    />
  );
}

ProfileImage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  src: PropTypes.string,
};

export default ProfileImage;
