import React from "react";
import {
  Link,
  Avatar,
  Grid,
  CssBaseline,
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TablePagination,
} from "@mui/material";
import LinearGradientOne from "../components/layout/backgrounds/LinearGradientOne";
import { useTheme } from "@mui/material";
import useStore from "../store";

const Leaderboard = () => {
  const theme = useTheme();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(16);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userData = useStore((state) => state.userData);

  // const rows = Array.from({ length: 100 }).map(
  //   (_, index) =>
  //     userData || {
  //       id: 0,
  //       rank: 1,
  //       user: {
  //         name: "Aylo",
  //         profileImageUrl:
  //           "https://ww4.gogoanime2.org/images/225_318/tonari-no-youkai-san.jpg",
  //       },
  //       wins: 10,
  //       losses: 1,
  //       latestWin: {
  //         name: "Exinal",
  //         profileImageUrl:
  //           "https://api.dicebear.com/7.x/pixel-art/png?seed=Exinal&background=%23000000",
  //       },
  //       latestLoss: {
  //         name: "Exinal",
  //         profileImageUrl:
  //           "https://api.dicebear.com/7.x/pixel-art/png?seed=Exinal&background=%23000000",
  //       },
  //     }
  // );

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (rows.length === 0) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/features/account/lb_players`
      )
        .then((response) => response.json())
        .then((data) => {
          setRows(data.sort((a, b) => b.rank - a.rank));
        });
    }
  }, []);

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator("asc", "rank")).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, rows]
  );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100vw",
          height: "100%",
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      {/* <Grid item sx={{ width: "80vw", height: "85vh" }}> */}
      {/* <TableContainer
          component={Paper}
          sx={{
            marginTop: "10vh",
            width: "80vw",
            height: "85vh",
            backgroundColor: `${theme.palette.background.default}D9 !important`,
            overflowX: "visible",
          }}
        > */}
      <Grid
        item
        sx={{
          marginTop: "10vh",
          width: "80%",
          height: "85vh",
          overflowX: "auto",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          padding: "1rem",
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ backgroundColor: theme.palette.background.paper }}>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Wins</TableCell>
              <TableCell align="center">Losses</TableCell>
              <TableCell align="center">Latest Win</TableCell>
              <TableCell align="center">Latest Loss</TableCell>
              <TableCell align="center">Win %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              backgroundColor: `${theme.palette.background.paper}D9`,
              color: theme.palette.text.primary,
            }}
          >
            {visibleRows.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {index + 1 + page * rowsPerPage}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={row.username}
                      src={row.profileImage}
                      sx={{
                        width: 24,
                        height: 24,
                        marginRight: "0.5rem",
                      }}
                    />
                    <Link
                      sx={{
                        textDecoration: "none",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        color: theme.palette.text.primary,
                      }}
                      href={`/user/${row._id}`}
                    >
                      {row.username}
                    </Link>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {row.matchHistory?.wins || 0}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {row.matchHistory?.losses || 0}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={row.username}
                      src={row.profileImage}
                      sx={{
                        width: 24,
                        height: 24,
                        marginRight: "0.5rem",
                      }}
                    />
                    <Link
                      sx={{
                        textDecoration: "none",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        color: theme.palette.text.primary,
                      }}
                      href={`/user/${row._id}`}
                    >
                      {row.username}
                    </Link>
                  </Grid>
                </TableCell>

                <TableCell align="center">
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={row.username}
                      src={row.profileImage}
                      sx={{
                        width: 24,
                        height: 24,
                        marginRight: "0.5rem",
                      }}
                    />
                    <Link
                      sx={{
                        textDecoration: "none",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        color: theme.palette.text.primary,
                      }}
                      href={`/user/${row._id}`}
                    >
                      {row.username}
                    </Link>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {row.wins + row.losses > 0
                      ? ((row.wins / (row.wins + row.losses)) * 100).toFixed(2)
                      : 0}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Leaderboard;
