import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import LinearGradientOne from "../components/layout/backgrounds/LinearGradientOne";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import useStore from "../store";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

export default function SignUp() {
  const [signedUpEmail, setSignedUpEmail] = React.useState(null);
  const theme = useTheme();
  const userData = useStore((state) => state.userData);
  // const setUserData = useStore((state) => state.setUserData);
  const logout = useStore((state) => state.logout);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/features/account/sign_up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: data.get("username"),
        email: data.get("email"),
        password: data.get("password"),
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "Account created") {
          setSignedUpEmail(data.get("email"));
        } else {
          alert(responseJson.error.details);
        }
      });
  };

  return userData !== null && userData !== undefined ? (
    <Grid
      container
      component="main"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Grid
        item
        sx={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 1,
          top: 0,
          left: 0,
        }}
      >
        <LinearGradientOne />
      </Grid>
      <Grid
        item
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem",
          backgroundColor: `${theme.palette.background.default}D9 !important`,
          maxWidth: "80vw",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          You are signed in as {userData.name}.
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            sx={{
              width: "100%",
            }}
          >
            Go to Home
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/profile"
            sx={{
              width: "100%",
            }}
          >
            Go to Profile
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            onClick={logout}
            to="/"
            sx={{
              width: "100%",
            }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <div>
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Grid
          item
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100vw",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <LinearGradientOne />
        </Grid>
        <Grid
          item
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "2.5rem",
            backgroundColor: `${theme.palette.background.default}D9 !important`,
            maxWidth: "50rem",
            margin: "0 2.5rem",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="I want to receive notification emails from this website."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login">Already have an account? Sign in</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 5 }} /> */}
          {signedUpEmail && (
            <Typography sx={{ mt: 5, color: "success.main" }}>
              You have successfully signed up with {signedUpEmail}. Please check
              your email
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
